<script>
import Layout from '@layouts/main'
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import swalFeedbackVue from '@src/mixins/swalFeedback.vue'
import PageHeader from '@components/PageHeader'
import ExportJobService from '@src/services/ExportJobService'
import ApiModeVuetable from '@src/components/ApiModeVuetable'
import exportJobFields from '@src/fields/exportJobFields'
import filterVuetable from '@src/mixins/filterVuetable'
import FilterLabelButton from '@/src/components/FilterLabelButton.vue'
import exportJobFilterModal from '@/src/router/views/exportJob/exportJobFilterModal.vue';

const i18nCommon = 'COMMON'
const i18nKey = 'EXPORT_JOBS'

export default {
    components: {
        Layout,
        Breadcrumb,
        PageHeader,
        ApiModeVuetable,
        FilterLabelButton,
        exportJobFilterModal,
    },
    mixins: [swalFeedbackVue, exportJobFields, filterVuetable],
    data() {
        return {
            sortOrder: [
                {
                    field: 'created_at',
                    direction: 'desc',
                },
            ],
            i18nCommon: i18nCommon,
            i18nKey: i18nKey,
            perPage: 10,
            additionalParameters: {
                with: ['file', 'user'],
            },
            filters: {
                createdAt: null,
                type: [],
                status: null,
                users: [],
            },
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nCommon,
                        suffix: 'report',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.export',
                        modifier: 2,
                    }),
                    active: true,
                },
            ]
        },
        i18nNewJustification() {
            return this.getI18n(i18nKey, 'BUTTONS.new_justification')
        },
        i18nUser() {
            return this.getI18n('USERS', 'TITLES.user')
        },
        i18nBusinessUnit() {
            return this.getI18n(i18nCommon, 'business_unit')
        },
        i18nDate() {
            return this.getI18n(i18nCommon, 'date')
        },
        i18nExportJob() {
            return this.getI18nModified({
                prefix: 'EXPORT_JOBS.TITLES',
                suffix: 'export',
                modifier: 2,
            })
        },
        i18nRequestBackground() {
            return this.getI18n('RESPONSES', 'request_background')
        }
    },
    mounted() {
        if (this.$route.params.newJobCreated) {
            this.$bvToast.toast(this.i18nRequestBackground,
            {
                title: this.i18nExportJob,
                variant: 'success',
                autoHideDelay: 8000,
            })
        }
    },
    methods: {
        createAdditionalParameters() {
            return this.formatParameters({
                with: ['file', 'user'],
                user_id: this.filters.users.map((user) => user.id),
                type: this.filters.type.map((type) => type.value),
                status: this.filters.status ? String(this.filters.status.value) : null,
                created_at: this.formatFilterDate(this.filters.createdAt),
            })
        },

        refreshTable() {
            this.$refs.vuetable.refresh()
        },

        fetch(url, params) {
            return ExportJobService.fetchVuetable(url, params)
        },

        filter(appliedFilters) {
            this.filters = appliedFilters;
            this.additionalParameters = this.createAdditionalParameters()
            this.refreshTable()
        },

        showFilterModal() {
            this.$nextTick(() => this.$refs.exportJobFilterModal.showModal(this.filters))
        },
    },
}
</script>

<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3">
                <div class="col-sm-6 mb-3 form-inline"></div>
                <div class="col-sm-6 mb-3">
                    <div class="text-sm-right">
                        <filter-label-button @onClick="showFilterModal" />
                    </div>
                </div>
            </div>
            <div class="card card-pdv">
                <PageHeader :title="i18nExportJob" />
                <div class="card-body">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetch"
                        :fields="exportJobFields"
                        :per-page="perPage"
                        :additional-parameters="additionalParameters"
                        :sort-order="sortOrder">
                        <template slot="file" slot-scope="props">
                            <div v-if="props.rowData.file && props.rowData.file.url">
                                <a :href="props.rowData.file.url">
                                    <i class="fe-download" />
                                </a>
                            </div>
                        </template>
                    </api-mode-vuetable>
                </div>
            </div>
        </div>
        <export-job-filter-modal
            ref="exportJobFilterModal"
            :handle-submit="filter"
        />
    </Layout>
</template>
