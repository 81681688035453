<template>
    <form-modal
        ref="formModal"
        :title="getI18nModified({ prefix: 'COMMON.filter', modifier: 2 })"
        @handleHide="handleHide">
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <b-form-row>
                    <b-col md="12">
                        <b-form-group
                            :label="i18nType"
                            label-for="filter-type"
                            class="label-pdv">
                            <translated-multiselect
                                id="filter-type"
                                v-model="filterModal.type"
                                :options="importTypes"
                                track-by="value"
                                label="text"
                                :searchable="true"
                                :multiple="true"
                            />
                        </b-form-group>
                        <b-form-group
                            :label="i18nStatus"
                            label-for="filter-status"
                            class="label-pdv">
                            <translated-multiselect
                                id="filter-status"
                                v-model="filterModal.status"
                                :options="importStatus"
                                track-by="value"
                                label="text"
                            />
                        </b-form-group>
                        <b-form-group
                            :label="i18nUser"
                            label-for="filter-user"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-user'"
                                ref="userMultiselect"
                                v-model="filterModal.users"
                                :service="'users'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="usersParameters"
                            />
                        </b-form-group>
                        <b-form-group
                            :label="i18nCreatedAt"
                            label-for="filter-started-at-date-time"
                            class="label-pdv">
                            <date-range-picker
                                :value.sync="filterModal.createdAt"
                                :max-date="new Date()"
                            />
                        </b-form-group>
                    </b-col>
                </b-form-row>
            </form>
        </template>
        <template v-slot:footer>
            <save-button
                ref="okButton"
                :busy="submitLoading"
                :disabled="submitLoading"
                :title="getI18n('ACTION.filter')"
                @onClick="handleOk"
            />
            <clear-filter-button
                @onClick="handleClearFilter"
            />
        </template>
    </form-modal>
</template>

<script>
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import FormModal from '@/src/components/FormModal.vue'
import translatedMultiselect from '@src/components/translatedMultiselect'
import SaveButton from '@/src/components/SaveButton'
import ClearFilterButton from '@/src/components/ClearFilterButton'
import cloneObject from '@utils/clone-object'
import DateRangePicker from '@/src/components/DateRangePicker'
import TranslatedMultiselect from '@/src/components/translatedMultiselect.vue'
import multiSelectWithService from '@src/components/multiSelectWithService'
import csvFields from '@src/mixins/csvFields'

const i18nKey = 'IMPORT_JOBS'
const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
        DateRangePicker,
        translatedMultiselect,
        SaveButton,
        ClearFilterButton,
        TranslatedMultiselect,
        multiSelectWithService,
    },
    mixins: [
        validationMixin,
        swalFeedback,
        csvFields
    ],
    props: {
        handleSubmit: {
            type: Function,
            required: true,
            default: function () {},
        },
    },
    data() {
        return {
            i18nKey: i18nKey,
            i18nCommon: i18nCommon,
            filterModal: {},
            submitLoading: false,
            usersParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
                active: true,
            },
        }
    },
    validations: {},
    computed: {
        i18nType() {
            return this.getI18n(i18nKey, 'FIELDS.type')
        },
        i18nStatus() {
            return this.getI18n(i18nKey, 'FIELDS.status')
        },
        i18nUser() {
            return this.getI18n('USERS', 'TITLES.user')
        },
        i18nCreatedAt() {
            return this.getI18n(i18nCommon, 'created_at')
        },
    },
    methods: {
        showModal(previousFilters) {
            this.filterModal = cloneObject(previousFilters);
            this.submitLoading = false
            this.$refs.formModal.show()
        },
        cleanModal() {
            this.filterModal = Object.assign(this.filterModal, {
                createdAt: null,
                type: [],
                status: null,
                users: [],
            })
        },
        handleHide() {
            if (!this.submitLoading) {
                this.cleanModal();
            }
        },
        handleOk() {
            if (this.submitLoading) {
                return;
            }

            this.submitLoading = true;
            this.$props.handleSubmit(this.filterModal);
            this.$refs.formModal.hide()
        },
        async handleClearFilter() {
            this.cleanModal()
        }
    },
}
</script>
